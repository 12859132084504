<template>
  <div>
    <el-dialog width="62%" :close-on-click-modal="false" title="供应商变更日志" :visible.sync="visible">
      <el-table
        :data="changVendorLog"
        border
        max-height="450px"
      >
        <el-table-column prop="createTime" label="时间" width="180" align="center" sortable />
        <el-table-column prop="createByName" label="发起人" width="120" align="center" sortable />
        <!-- <el-table-column prop="approveContent" label="内容" width="190" align="center" sortable>
          <template slot-scope="scope">
            <el-popover placement="top-start" trigger="hover" width="190" :content="scope.row.approveContent"><span
              slot="reference"
            >{{ scope.row.approveContent }}</span>
            </el-popover>
          </template>
        </el-table-column> -->
        <el-table-column :show-overflow-tooltip="true" prop="approveContent" label="内容" width="190" align="center" sortable />
        <el-table-column prop="approveName" label="审核人" min-width="120" align="center" sortable />
        <el-table-column prop="approvalStateI18" label="审核状态" width="120" align="center" sortable />
        <!-- <template slot-scope="scope">
            {{ getAuditTypeLabel('VENDOR_APPROVAL_STATE',scope.row.approvalState) }}
          </template>
        </el-table-column> -->
        <el-table-column prop="approveResult" label="原因" min-width="120" align="center" sortable />

      </el-table>
      <Paging :pager="pager" end @pagination="pagerUpdate" />
      <el-row class="grid-content" type="flex" justify="center">
        <el-button @click="visible = false">关闭</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getVendorChangeLogListByVendorId } from '@/api/blurbInformation.js'
import Paging from '@/components/Pagination'
export default {
  name: 'ViewLog',
  components: { Paging },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    vendorId: {
      type: Number,
      default: () => (0)
    }
  },
  data() {
    return {
      changVendorLog: [],
      pager: {
        total: 0,
        current: 1,
        size: 10
      }
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(visible) {
        this.$emit('input', visible)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        Object.assign(this.$data, this.$options.data.call(this))
      } else {
        this._getVendorChangeLogListByVendorId()
      }
    }
  },
  mounted() {

  },
  methods: {
    // 变更日志查询\
    async _getVendorChangeLogListByVendorId(row) {
      const params = Object.assign({}, this.pager, { vendorId: this.vendorId })
      const { datas: { pager, records }} = await getVendorChangeLogListByVendorId(params)
      this.changVendorLog = records
      this.pager = pager
      this.visibleLog = true
    },
    pagerUpdate(val) {
      this.pager = val
      this._getVendorChangeLogListByVendorId()
    }
  }

}
</script>
<style lang="css" scope>
.el-tooltip__popper{
  font-size: 15px;
  max-width:65%;
  color: #000 !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff !important;  /*背景色!important优先级*/
} /*设置显示隐藏部分内容，按50%显示*/

</style>
