
<template>
  <!-- 供应商管理 -->
  <div class="app-container">

    <el-form ref="form" :model="form" label-width="130px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" :label="$t('title.vendorName')">
              <el-input v-model="form.vendorName" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" :label="$t('title.vendorType')">
              <el-select v-model="form.vendorLevel" filterable :placeholder="$t('page.selectPlaceholder')">
                <el-option :label="$t('page.firstLevelSuppliers')" value="1" />
                <el-option :label="$t('page.secondLevelSuppliers')" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('page.status')">
              <el-select v-model="form.useStatus" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option
                  v-for="item in getDictionariesOpt('USE_STATUS')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="供应商管理工程师">
              <el-cascader
                ref="cascaderRef1"
                v-model="form.vendorManageEngineerFeishuUserIdList"
                clearable
                filterable
                :show-all-levels="false"
                :options="tagData"
                :props=" { emitPath:false,value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
                @change="(val)=>cascaderChange(val,'cascaderRef1')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="采购员">
              <el-cascader
                ref="cascaderRef2"
                v-model="form.purchaseFeishuUserId"
                clearable
                :show-all-levels="false"
                filterable
                :options="tagData"
                :props=" { value: 'feishuUserId', label: 'name', children: 'childList' }"
                @change="(val)=>cascaderChange(val,'cascaderRef2')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="技术工程师">
              <el-cascader
                ref="cascaderRef3"
                v-model="form.technologyEngineerFeishuUserIdList"
                clearable
                :show-all-levels="false"
                filterable
                :options="tagData"
                :props=" {emitPath:false,value: 'feishuUserId', label: 'name', children: 'childList' ,multiple: true}"
                @change="(val)=>cascaderChange(val,'cascaderRef3')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开发工程师">
              <el-cascader
                ref="cascaderRef4"
                v-model="form.developEngineerFeishuUserIdList"
                clearable
                filterable
                :show-all-levels="false"
                :options="tagData"
                :props=" {emitPath:false,value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
                @change="(val)=>cascaderChange(val,'cascaderRef4')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="品质QA工程师">
              <el-cascader
                ref="cascaderRef5"
                v-model="form.qualityQaEngineerFeishuUserId"
                clearable
                :show-all-levels="false"
                filterable
                :options="tagData"
                :props=" { value: 'feishuUserId', label: 'name', children: 'childList' }"
                @change="(val)=>cascaderChange(val,'cascaderRef5')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="品质QC工程师">
              <el-cascader
                ref="cascaderRef6"
                v-model="form.qualityQcEngineerFeishuUserIdList"
                clearable
                :show-all-levels="false"
                filterable
                :options="tagData"
                :props=" { emitPath:false,value: 'feishuUserId', label: 'name', children: 'childList',multiple: true }"
                @change="(val)=>cascaderChange(val,'cascaderRef6')"
              >
                <template slot-scope="{ data }">
                  {{ data.name }}
                </template>
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域">
              <el-select v-model="form.areaCode" :placeholder="$t('page.selectPlaceholder')" clearable>
                <el-option
                  v-for="item in _getAllCommodityDict('AREA_CODE')"
                  :key="item.val"
                  :value="item.val"
                  :label="item.label"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="供应商标识">
              <el-input v-model="form.vendorIdentification" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="送货方式">
              <Select
                v-model="form.deliveryMethod"
                :select-options="_getAllCommodityDict('VENDOR_DELIVERY_METHOD')"
                :configuration="optionsConfig"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收货地点">
              <Select
                v-model="form.receiptPlaceCodeList"
                api-key="warehouseList"
                clearable
                multiple
              />
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <router-link to="added" append>
          <el-button class="mr-3" icon="el-icon-plus" type="primary">{{ $t('page.create') }}</el-button>
        </router-link>
        <el-button v-permission="'changeStatus'" class="mr-2" type="primary" @click="handleStatus">启用/禁用</el-button>
        <el-button v-permission="'vendorExport'" class="mr-3" type="primary" :loading="exportLoading" @click="exportConfirm">导出</el-button>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <router-link to="changeVendorName" append>
          <el-button class="mr-3" icon="el-icon-plus" type="primary">新增供应商变更</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-table ref="multipleTable" :header-cell-style="{background:'#fafafa'}" class="mb-3" :data="tableDatas" @selection-change="SelectionChange">
      <el-table-column type="selection" />
      <el-table-column :label="$t('page.No')" type="index">
        <template slot-scope="scope">
          {{ scope.row.No }}
        </template>
      </el-table-column>
      <el-table-column prop="vendorCode" align="center" :label="$t('title.vendorCode')" width="100" />
      <el-table-column prop="vendorIdentification" align="center" label="供应商标识" />
      <el-table-column prop="vendorManageEngineerName" align="center" label="供应商管理工程师" />
      <el-table-column prop="purchaseName" align="center" label="采购员" />
      <el-table-column prop="technologyEngineerName" align="center" label="技术工程师" />
      <el-table-column prop="developEngineerName" align="center" label="开发工程师" />
      <!--<el-table-column prop="qualityQaEngineerName" align="center" label="品质QA工程师" />-->
      <el-table-column prop="qualityQcEngineerName" align="center" label="品质QC工程师" />
      <el-table-column prop="areaCodeI18" align="center" label="区域" />
      <el-table-column align="center" prop="vendorName" :label="$t('title.vendorName')" />
      <el-table-column prop="deliveryMethodI18" align="center" label="送货方式" />
      <el-table-column prop="receiptPlaceName" align="center" label="收货地点" />
      <el-table-column align="center" prop="vendorFull" :label="$t('title.vendorFull')" width="100" />
      <el-table-column prop="vendorNameEn" align="center" label="供应商英文名称" />
      <el-table-column align="center" prop="vendorSimple" :label="$t('title.vendorSimple')" width="100" />
      <el-table-column align="center" prop="vendorLevel" :label="$t('title.vendorType')" width="100">
        <template slot-scope="scope">
          <p v-if="scope.row.vendorLevel==1">
            {{ $t('page.firstLevelSuppliers') }}
          </p>
          <p v-else-if="scope.row.vendorLevel==2">
            {{ $t('page.secondLevelSuppliers') }}
          </p>

        </template>
      </el-table-column>
      <el-table-column align="center" prop="vendorType" label="供应商类型" width="100">
        <template slot-scope="scope">
          <p v-if="scope.row.vendorType==1">
            潜在客户
          </p>
          <p v-if="scope.row.vendorType==2">
            合作伙伴
          </p>
          <p v-if="scope.row.vendorType==3">
            竞争对手
          </p>
          <p v-if="scope.row.vendorType==4">
            生产厂商
          </p>
          <p v-if="scope.row.vendorType==5">
            货主
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="cargoOwnerName" align="center" label="货主" />
      <el-table-column align="center" label="订金比例">
        <template slot-scope="scope">
          <span v-if="scope.row.depositRate">{{ scope.row.depositRate }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="depositAccountPeriod" label="订金账期" />
      <el-table-column align="center" label="首款比例">
        <template slot-scope="scope">
          <span v-if="scope.row.firstRate"> {{ scope.row.firstRate }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="firstAccountPeriod" label="首款账期" />
      <el-table-column align="center" label="尾款比例">
        <template slot-scope="scope">
          <span v-if="scope.row.lastRate">{{ scope.row.lastRate }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="lastAccountPeriod" label="尾款账期" />
      <!-- <el-table-column align="center" prop="address" :label="$t('page.address')" /> -->
      <el-table-column fixed="right" align="center" prop="useStatus" label="供应商状态">
        <template slot-scope="scope">
          <span v-if="scope.row.useStatus == 1" class="use-status-open">{{ scope.row.useStatusI18 }}</span>
          <span v-else class="use-status-close">{{ scope.row.useStatusI18 }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="center" prop="approvalStateI18" label="审核状态">
        <template slot-scope="scope">
          <!-- <span v-if="scope.row.approvalStateI18 == 1" class="use-status-open">{{ scope.row.approvalStateI18 }}</span> -->
          <span>{{ scope.row.approvalStateI18 }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" prop="createTime" align="center" :label="$t('page.createTime')" />
      <el-table-column fixed="right" :label="$t('page.operate')" width="90">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modifyClick(scope.row.id,false)">{{ $t('page.modify') }}
          </el-button>
          <el-button type="text" size="small" @click="modifyClick(scope.row.id,true)">{{ $t('page.view') }}</el-button>
          <el-button type="text" size="small" @click="changLog(scope.row)">变更日志</el-button>
        </template>
      </el-table-column>

    </el-table>
    <div class="block">
      <el-pagination
        background
        class="pt-4 text-xs-right"
        :current-page="pager.current"
        :page-sizes="[10, 50, 200, 500]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <ViewLog v-model="visibleLog" :vendor-id="vendorId" />
  </div>
</template>
<script>
// import { ExcelExport } from '@/api/scm-api'
import { queryVendor, getAllDepartmentInfo } from '@/api/blurbInformation.js'
import { handleDownload } from '@/utils'
import { exportVendor, disableOrEnable } from '@/api/audit-api.js'
import ViewLog from '@/views/third-party/component/VendorLog'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Select from '@/components/Selection'
import { Mixin } from '@/mixin/mixin.js'
export default {
  components: {
    ViewLog,
    Select
  },
  mixins: [Mixin, commodityInfoDict],
  data() {
    return {
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      vendorId: 0,
      visibleLog: false,
      showRow: false, // 切换显示input
      tableDatas: [],
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      pagers: {
        current: 1,
        size: 10,
        total: 0
      },
      tagData: [],
      changVendorLog: {},
      row: '',
      form: {
        vendorName: '',
        vendorType: '4',
        vendorLevel: '',
        useStatus: '',
        vendorManageEngineerFeishuUserIdList: [],
        purchaseFeishuUserId: [],
        technologyEngineerFeishuUserIdList: [],
        developEngineerFeishuUserIdList: [],
        // qualityQaEngineerFeishuUserId: [],
        qualityQcEngineerFeishuUserIdList: [],
        areaCode: ''
      },
      // developEngineerFeishuUserId: '',
      multipleSelection: [],
      exportLoading: false
    }
  },
  computed: {
    // 生成对应的请求参数
    queryParameter() {
      const { vendorIdentification, vendorName, vendorType, vendorLevel, useStatus, areaCode,
        developEngineerFeishuUserIdList, qualityQcEngineerFeishuUserIdList,
        deliveryMethod, receiptPlaceCodeList,
        vendorManageEngineerFeishuUserIdList, purchaseFeishuUserId, technologyEngineerFeishuUserIdList } = this.form
      return Object.assign({}, { vendorManageEngineerFeishuUserIdList: vendorManageEngineerFeishuUserIdList.map(item => String(item)),
        technologyEngineerFeishuUserIdList: technologyEngineerFeishuUserIdList.map(item => String(item)),
        developEngineerFeishuUserIdList: developEngineerFeishuUserIdList.map(item => String(item)),
        // qualityQaEngineerFeishuUserId: qualityQaEngineerFeishuUserId && qualityQaEngineerFeishuUserId[qualityQaEngineerFeishuUserId.length - 1],
        qualityQcEngineerFeishuUserIdList: qualityQcEngineerFeishuUserIdList.map(item => String(item)),
        purchaseFeishuUserId: purchaseFeishuUserId && purchaseFeishuUserId[purchaseFeishuUserId.length - 1] }, { vendorIdentification, vendorName, vendorType,
        vendorLevel, useStatus, areaCode, deliveryMethod, receiptPlaceCodeList }, this.pager)
    }
  },
  mounted() {
    this._queryVendor(this.queryParameter)
    this._getAllDepartmentInfo()
  },
  methods: {
    cascaderChange(val, ref) {
      if (val && val.length === 0) {
        const _cascader = this.$refs[ref]
        if (_cascader) {
          _cascader.$refs.panel.checkedValue = [] // 清空选中值
          _cascader.$refs.panel.clearCheckedNodes() // 清空级联选择器选中状态
          _cascader.$refs.panel.activePath = [] // 清除高亮
          _cascader.$refs.panel.syncActivePath() // 初始化（只展示一级节点）
        }
      }
    },
    // 导出
    async exportConfirm() {
      try {
        this.exportLoading = true
        const data = await exportVendor(this.queryParameter, this.pager)
        handleDownload(data, '供应商管理文件.xlsx')
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },

    // 获取树结构数据
    async _getAllDepartmentInfo() {
      const { datas } = await getAllDepartmentInfo({ cacheFlag: true })
      this.tagData = datas
      this.getDataTree(this.tagData)
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return []
      data.map(item => {
        Object.assign(item, {
          disabled: (item.childList == null || item.childList.length < 1) && item.departmentId !== null,
          feishuUserId: item.feishuUserId || Math.random().toString()
        })
        this.getDataTree(item.childList)
      })
    },
    // 变更日志查询\
    async changLog(row) {
      this.vendorId = row.id
      this.visibleLog = true
    },

    // 点击跳转到容器管理新增/修改
    modifyClick(row, disabled) {
      this.$router.push({
        path: 'added',
        append: 'true',
        query: { 'id': row, 'disabled': disabled }
      })
    },
    // 点击查询获取信息
    queryClick(query) {
      this._queryVendor(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick(val) {
      this.form = this.$options.data.call(this).form
      this.form.vendorManageEngineerFeishuUserIdList = []
      this.form.purchaseFeishuUserId = []
      this.form.technologyEngineerFeishuUserIdList = []
      this.form.developEngineerFeishuUserIdList = []
      // this.form.qualityQaEngineerFeishuUserId = []
      this.form.qualityQcEngineerFeishuUserIdList = []
      const cascaderArr = ['cascaderRef1', 'cascaderRef2', 'cascaderRef3', 'cascaderRef4', 'cascaderRef5', 'cascaderRef6']
      cascaderArr.map(e => {
        const _cascader = this.$refs[e]
        if (_cascader) {
          _cascader.$refs.panel.checkedValue = [] // 清空选中值
          _cascader.$refs.panel.clearCheckedNodes() // 清空级联选择器选中状态
          _cascader.$refs.panel.activePath = [] // 清除高亮
          _cascader.$refs.panel.syncActivePath() // 初始化（只展示一级节点）
        }
      })
      this._queryVendor(this.queryParameter)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 获取容器信息数据
    async _queryVendor(parameter) {
      let No = 0
      const { datas: { pager, records }} = await queryVendor(parameter, this.pager)
      No = pager.current * pager.size - pager.size
      this.tableDatas = records.map(e => { e.No = No += 1; return e })
      this.pager = pager
    },

    //  列表分页方法
    handleSizeChange(val) {
      this.pager.size = val
      this._queryVendor(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._queryVendor(this.queryParameter)
    },
    // 启用/禁用供应商
    SelectionChange(val) {
      this.multipleSelection = val
    },
    handleStatus() {
      if (!this.multipleSelection.length) {
        return this.$message({
          message: '请至少选择一条数据',
          type: 'warning'
        })
      }
      if (this.multipleSelection.some(item => item.approvalState === '0')) {
        return this.$message.warning('正在审批中，请勿操作')
      }
      if (this.multipleSelection.some(item => item.useStatus !== this.multipleSelection[0].useStatus)) {
        return this.$message.warning('请选择同一状态的供应商进行操作')
      }
      this.$confirm('请确认是否禁用/启用该供应商，点击确定后触发审批', '操作确认', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async() => {
          const vendorCodeList = this.multipleSelection.map(item => item.vendorCode)
          await disableOrEnable(vendorCodeList)
          this.$message({
            type: 'success',
            message: '操作成功'
          })
          this._queryVendor(this.queryParameter)
        })
    }

  }
}
</script>

